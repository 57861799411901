import { getAppVariable } from "@white-label-helper/get-app-variable";
import { datadogRum } from "@datadog/browser-rum";

export const addCustomMetric = (metricType: 'tracking' | 'error', name: string, context: Record<string, string | number | undefined>, startTime: number, contextKey: string) => {
	const metricContext = typeof context === 'object' && context !== null ? context : {};
	const endTime = performance.now();
	const duration = (endTime - startTime) / 1000;

	if (metricType === 'tracking') {
		datadogRum.addAction(name, {
			[contextKey]: {
				...metricContext,
				env: process.env['NODE_ENV'] === 'production' ? 'live' : 'dev',
				channel_id: getAppVariable('channel').id,
				duration_in_seconds: duration.toFixed(2),
				airport_code: getAppVariable('poi.code'),
			}
		});
	}

	if (metricType === 'error') {
		datadogRum.addError(name, {
			[contextKey]: {
				...metricContext,
				env: process.env['NODE_ENV'] === 'production' ? 'live' : 'dev',
				channel_id: getAppVariable('channel').id,
				duration_in_seconds: duration.toFixed(2),
				airport_code: getAppVariable('poi.code'),
			}
		});
	}
}
