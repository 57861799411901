
import { defineComponent } from 'vue';
import { MetaInfo } from 'vue-meta';

// Config
import { GTM_URLS_BY_ID } from '@white-label-configuration/constants';

export default defineComponent({
  name: 'EmptyLayout',

  head(): MetaInfo {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      script: [
        {
          json: this.$partnerConfig as { [key: string]: any },
          type: 'application/json',
          vmid: 'partner-channel-config',
        },
      ]
    }
  },

  mounted() {
    // load GTM
    const gtmId = this.$partnerConfig ? this.$partnerConfig.gtm_id as string : process.env.NUXT_ENV_GTM_ID as string;
    const scriptURL = GTM_URLS_BY_ID[gtmId];
    this.$bootstrapGTM({ id: gtmId, scriptURL });
  }
});
